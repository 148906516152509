import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Package from "../../components/GSTTemp/Package";

export default function ChangeInObjectClause() {
  /* Slider */
  // const ChangeInObjSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 20,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Change in Object Clause Service`,
  //     content: `Apply for Change in Object Clause Online with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/llp-slider.png",
  //     alt_tag: "Best Online Change in Object Clause in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Change In Object Clause`,
    buyBtnLink: `#pricing-buy`,
    price: `2999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const ChangeInObjAboutData = {
    header: `Change in Object Clause`,
    sub_title: `Change in object clause with TODAYFILINGS Experts.`,
    content_paragraph: [
      // `Memorandum of Association, or MOA, is shortened. Any company
      // that is registered must have a MOA of Company as its
      // foundation. It is thought that the MOA serves as the
      // company's charter and establishes the range of rights and
      // obligations under which the company may function.`,

      `The Company should only conduct its business in accordance
      with its Memorandum of Association (MOA). The corporation is
      not permitted to violate any of the clauses of the MOA. If
      it does, the business transaction or dealing will be ultra
      and void.If there is a conflict between a clause in a memorandum and a
      provision of the Companies Act, the law will take precedence.`,
    ],
  };
  /* Scroll Nav Data */
  const ChangeInObjScrollId = [
    {
      id: `#roc`,
      heading: `ROC`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#modifying`,
      heading: `Modifying`,
    },
    {
      id: `#moa`,
      heading: `MOA`,
    },
  ];

  /* Img Content Component Data */
  const ChangeIcInObjData = {
    id: 'roc',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Registrar of Companies (ROC)',
    image: '/imgs/business-plan.png',
    alt_tag: "Company's MOA's Object Clause Amendment Process",
    Paragraph: '',
    points: [
      {
        head: `ROC Filing`,
        content: `After adopting the Special Resolution, the Company
      must file Form MGT-14 with the authorized Registrar
      along with the resolution adopted for the MOA's
      alternating object clause process. The list of
      attachments that must be included with the form is
      shown below. The prescribed charge for proposing a
      resolution of this nature is listed below.`,
        icon: true,
      },
      {
        content: `Certificate for a trademark`,
        icon: true,
      },
      {
        content: `Special Resolution CTC`,
        icon: true,
      },
      {
        content: `Notice of EGM`,
        icon: true,
      },
      {
        content: `Passing the CTC of Board resolution`,
        icon: true,
      },
      {
        content: `Modified MOA`,
        icon: true,
      },
      {
        head: `Acceptance by ROC`,
        content: `After adopting the Special Resolution, the Company
      must file Form MGT-14 with the authorized Registrar
      along with the resolution adopted for the MOA's
      alternating object clause process. The list of
      attachments that must be included with the form is
      shown below. The prescribed charge for proposing a
      resolution of this nature is listed below.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const ChangeInObjCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: "Company's MOA's Object Clause Amendment Process",
    content: `If the company wishes to engage in business operations
    that are not specifically included in its object
    clause, it must first be altered to include the
    additional objects clause in accordance with
    secretarial procedure:`,
    body: [
      {
        head: 'Conduct the Board Meeting',
        points: `Send a 7-day notice by mail to hold the board
        meeting and discuss the following agenda:`,
        icon: true,
      },
      {
        points: `Obtain the directors' consent before changing the
        object provision in the MOA.`,
        icon: true,
      },
      {
        points: `Set the location, date, and hour of the
        Extraordinary General Meeting to be held to seek
        shareholder approval to change the object clause.`,
        icon: true,
      },
      {
        points: `In accordance with Section 102 of the 2013 Companies
        Act, approve the EGM notice containing the agenda
        for the meeting to be considered and an explanation
        report.`,
        icon: true,
      },
      {
        head: 'Conduct EGM',
        points: `Obtain the consent of the company's shareholders
        through a special EGM resolution. Such a special
        resolution needs to be approved by at least 3/4 of
        the members. A copy of the special resolution and
        the material that preceded it must be given by the
        firm to the stock exchange where its shares are
        traded.`,
        icon: true,
      },
      {
        points: `Corporation with almost 200 members.`,
        icon: true,
      },
      {
        points: `Business that has such unspent capital that was
        raised through the issuance of a prospectus.`,
        icon: true,
      },
    ],
  };
  /*  Content Img Component Data */
  const ChangeInObjCiData = {
    id: 'moa',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'MOA of the companies',
    paragraph: ``,
    points: [
      {
        content: `Companies limited by shares`,
        icon: true,
      },
      {
        content: `Unlimited Companies`,
        icon: true,
      },
      {
        content: `Unlimited Companies and having a share capital`,
        icon: true,
      },
      {
        content: `Companies limited by guarantee and have no share
        capital`,
        icon: true,
      },
      {
        head: `Items mentioned in the company's MOA`,
        content: `Name Clause`,
        icon: true,
      },
      {
        content: `Object Clause`,
        icon: true,
      },
      {
        content: `Liability Clause`,
        icon: true,
      },
      {
        content: `Capital Clause`,
        icon: true,
      },
      {
        content: `Registered Address Clause`,
        icon: true,
      },
    ],
    image: '/imgs/registration/shop/shop-slider.png',
    alt_tag: 'MOA of the companies',
  };

  /* Img Slider */
  const imgSlider = [
    '/imgs/assited/post-incorpration.png',
    '/imgs/assited/management.png',
    '/imgs/registration/msme/ms-me.png',
  ];

  /*  Slider Content Component Data */
  const ChangeInObjScData = {
    id: 'modifying',
    background: [],
    mt_div: 'mt-3',
    mt_img: 'mt-5',
    header: "Reason for Modifying the Company's Object Clause",
    paragraph: `Some of the explanations for why the Company modifies
      the MOA's object clause which may or may not be the
      same are listed below:`,
    content: [
      {
        points: `By broadening the scope of its company operations or
          activities, the Company may be willing to carry out
          its business activities on a greater scale.`,
        icon: true,
      },
      {
        points: `To use new or improved approaches to accomplish the
          company's current aims.`,
        icon: true,
      },
      {
        points: `To work on some extra business projects that can be
          easily integrated with the company's current business
          operations.`,
        icon: true,
      },
      {
        points: `To dispose of or sell the portion of the business
          operations that can change the organization's
          structure.`,
        icon: true,
      },
      {
        points: `If the Company merges or coalesces with other
          companies, the Company shall be required to enlarge
          the Object Clause as it currently exists.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  var FAQ_data = [
    {
      header: 'What is a MOA, or Memorandum of Association?',
      body: [
        {
          content: `Any company that is registered must have a MOA of Company as its
              foundation. It is thought that the MOA serves as the company's
              charter and establishes the range of rights and obligations under
              which the company may function.`,
          icon: false,
        },
      ],
    },

    {
      header:
        'What variations exist between the Object Clause provisions of the old and new acts?',
      body: [
        {
          content: `In the new online form SPICe 33 for MOA, only two of the original
                object clause's five provisions remain because of the Companies
                Act of 2013, such as`,
          icon: false,
        },
        {
          content: `Main objects`,
          icon: true,
        },
        {
          content: `Ancillary objects.`,
          icon: true,
        },
      ],
    },
    {
      header:
        'What limitations does Section 13(2) place on changing the Object Clause?',
      body: [
        {
          content: `A business that has such unspent capital that was raised through
          the issuance of a prospectus. Such Companies shall adopt a
          resolution prior to changing the object clause. Both English and
          regional newspapers must display or publish this resolution.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'If the Company is integrated by filing SPICe papers, is the copy of the MOA need to be supplied with the form MGT-14 when changing the object clause?',
      body: [
        {
          content: `An online SPICe form for the company's MOA is New E-form 33. For
          the certification of change, the company need not submit this
          online form once again along with form MGT-14. It will be
          sufficient to own a physical copy of MOA in addition to MGT 14.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the Object Clause of the Memorandum of the Company?',
      body: [
        {
          content: `Object clause is the clause in the MOA of the Company which defines the main business activity of 
          the company. It defines the main objects that the company is going to pursue after incorporation. The object
           clause also enlists the objects that are necessary/incidental for furtherance of the main objects i.e the 
           objects which help in conduct of the Main Objects of the Company or are necessary for the conduct of the 
           main objects.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How to intimate ROC for change in object clause?',
      body: [
        {
          content: `Filling of Form MGT-14 within 30 days of passing special resolution is the appropriate manner to 
          communicate the request for change in object clause of a company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What is main difference between main object and ancillary object?',
      body: [
        {
          content: `The main difference is that the main object defines main business activities of the
           company while ancillary objects contains incidental business activity which is related to main 
           business activity. These are always being mentioned in memorandum of company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'In case a company dealing in manufacturing and trading of motor parts wishes to start dealing in electronic goods, can it altogether change or add its business activity?',
      body: [
        {
          content: `If a Company having main object as manufacturing and trading of motor parts wants to 
          start altogether a new business activity of electronic goods, it can do so by changing its main objects 
          clause as per the provisions of the Companies Act, 2013. The compliance required on part of the Company 
          would be to call a Board meeting, Extraordinary General Meeting and then filing Form MGT-14 and altered 
          copies of MOA.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the Reason to Change Object Clause?',
      body: [
        {
          content: `The reasons for which a company alters its object clause are as follows. The given list may or 
          may not be the same for all companies:`,
          icon: false,
        },
        {
          content: `The company aspires to carry on its business on a big scale by enhancing the scope of its activities.`,
          icon: true,
        },
        {
          content: `For the purpose of obtaining the goals that have been already set by new or improved methods.`,
          icon: true,
        },
        {
          content: `To carry on the additional business that can easily be combined with the existing business of the company.`,
          icon: true,
        },
        {
          content: `If the company is getting merged with another company, it would need to expand its existing objective.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the Contents of Memorandum of Association?',
      body: [
        {
          content: `The following clauses are included in Memorandum of Association:`,
          icon: false,
        },
        {
          content: `Name Clause`,
          icon: true,
        },
        {
          content: `Registered Address Clause`,
          icon: true,
        },
        {
          content: `Liability Clause`,
          icon: true,
        },
        {
          content: `Object Clause`,
          icon: true,
        },
        {
          content: `Capital Clause`,
          icon: true,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Change in Object Clause'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ChangeInObjSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ChangeInObjAboutData} />

          <Counter />

          <ScrollNav scroll_data={ChangeInObjScrollId} />
          <ImgContent item={ChangeIcInObjData} />
          <ContentForm CFSection_data={ChangeInObjCfData} />
          <SliderContent ScSection_data={ChangeInObjScData} />
          <ContentImg CISection_data={ChangeInObjCiData} />

          <Cta />

          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
